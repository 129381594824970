import React from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { PublicRoutes } from "./routes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BaseLayout } from "./components/layout/Base";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: PublicRoutes,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
