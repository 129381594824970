import React from "react";

/**
 * Image function component
 */

function Image(props) {
  const imageStyle = {
    position: "relative",
    overflow: "hidden",
    width: props.width,
    height: props.height,
    objectFit: "cover",
    borderRadius: "50%",
  };

  // const emptyImage = {
  //   width: props.width,
  //   height: props.height,
  //   borderRadius: "50%",
  //   backgroundColor: "gray",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  // };

  return (
    <div style={{ margin: 10, maxWidth: props.width }}>
      <a href={props.link}>
        <img src={props.image} alt={props.alt} style={imageStyle} /> 
      </a>
      <p style={{ textAlign: "center" }}>{props.name}</p>
    </div>
  );
}

export default Image;
