import React from "react";
import { Container, Heading } from "react-bulma-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const HeadStyle = styled(Heading)`
  margin: 50px 80px;
  text-align: justify;
`;

const ParagraphStyle = styled.p`
  text-align: justify;
  margin: 35px 80px 0px;
`;

const ListStyle = styled.ul`
  margin: 5px 100px;
  text-align: justify;
  list-style: inside;
`;

const ContentContainer = styled.div``;

function Osiris() {
  const { t } = useTranslation();
  const list = t("OsirisLista", { returnObjects: true });
  const arrayList = list.split(";").map((item) => item.trim());

  return (
    <Container>
      <ContentContainer>
        <HeadStyle weight="light" size="2">
          <Link style={{ color: "black" }} to="/projetos-andamento">
            <RiArrowLeftSLine size="53" />
          </Link>
          {t("OsirisTitle")}
        </HeadStyle>
        <ParagraphStyle>{t("OsirisDescricao")}</ParagraphStyle>
        <ListStyle>
          {arrayList.map((item) => (
            <li>{item + ";"}</li>
          ))}
        </ListStyle>

        <ParagraphStyle>{t("OsirisAndamento")}</ParagraphStyle>
      </ContentContainer>
    </Container>
  );
}

export default Osiris;
