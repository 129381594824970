import React from "react";
import PeImage from "../../assets/pe-diabetico.png";
import { Container, Heading } from "react-bulma-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const HeadStyle = styled(Heading)`
  margin: 50px 80px;
  text-align: center;
`;

const ParagraphStyle = styled.p`
  text-align: justify;
  margin: 35px 70px 50px;
`;

function Pe() {
  const { t } = useTranslation();

  return (
    <Container>
      <div>
        <HeadStyle weight="light">
        <Link style={{color:'black', diaplay:'flex', alignSelf:'start'}} to="/projetos-concluidos"><RiArrowLeftSLine size="53"/></Link>
          {t("TituloPe")}
        </HeadStyle>
        <ParagraphStyle>{t("TxtPe")}</ParagraphStyle>
        <ParagraphStyle>{t("TxtPe2")}</ParagraphStyle>
        <img src={PeImage} alt="pe" width="378" height="196" />
      </div>
    </Container>
  );
}

export default Pe;
