import React from "react";
import { Card, Button } from "react-bulma-components";

function Item(props) {
  return (
    <div style={{ margin: "10px", justifyContent: "center" }}>
      <Card style={{ width: "860px", height: "84px" }}>
        <Card.Content>
          <div style={{ textAlign: "left" }}>
            <h4 style={{ display: "inline-block", fontSize: "22px" }}>
              {props.name}
            </h4>
            <Button
              style={buttonStyle}
              renderAs="a"
              href={props.href}
              color="black"
              outlined={true}
            >
              {"Ver"}
            </Button>
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}

const buttonStyle = {
  display: "inline-block",
  right: 0,
  position: "absolute",
  marginRight: "15px",
};

export default Item;
