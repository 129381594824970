import React from "react";
import Image from "./Image";

import { reseachers } from "../../data/team";

import { useTranslation } from "react-i18next";

/**y
 * Reseachers component
 */
function Management() {
  const { t } = useTranslation();

  return (
    <div>
      <p style={{ fontSize: "20px", margin: "25px" }}>{t("Pesquisadores")}</p>
      <div style={divStyle}>
        {reseachers.map((reseacher) => {
          return (
            <div style={{}}>
              <Image
                link={reseacher.linkedin}
                image={reseacher.image}
                alt={reseacher.name}
                width="200px"
                height="200px"
                name={reseacher.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

//Div CSS definitions
const divStyle = {
  // marginLeft: "50px",
  flex: 1,
  alignContentr: "stretch",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
};

export default Management;
