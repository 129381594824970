import React from "react";
import { Container } from "react-bulma-components";
import Item from "./DatasetItem";
import { useTranslation } from "react-i18next";

function Datasets() {
  const { t } = useTranslation();

  return (
    <Container style={datasetContainer}>
      <div>
        <header style={headerStyle}>Datasets</header>
        <p style={textStyle}>{t("DatasetTxt")}</p>
        <div style={{ display: "inline-block", justifyContent: "center" }}>
          <Item
            href="/victor/lrec2020"
            name="LREC 2020 - VICTOR"
          />
        </div>
      </div>
    </Container>
  );
}

const textStyle = {
  textAlign: "left",
  marginLeft: "35px",
  marginTop: "15px",
  marginBottom: "30px",
};

const headerStyle = {
  fontSize: "40px",
  textAlign: "left",
  margin: "25px",
};

const datasetContainer = {
  height: "100vh",
};

export default Datasets;
