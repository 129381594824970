import React from "react";
import { Container, Heading, Button } from "react-bulma-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const HeadStyle = styled(Heading)`
  margin: 50px 80px;
  text-align: justify;
`;

const ReferenceStyle = styled.p`
  margin: 15px 70px;
  text-align: justify;
`;

const ParagraphStyle = styled.p`
  text-align: justify;
  margin: 35px 70px 50px;
`;

const ButtonStyle = styled(Button)`
  margin-top: 35px;
`;

function Victor() {
  const { t } = useTranslation();

  return (
    <Container>
      <div>
        <HeadStyle weight="light" size="1">
          <Link style={{ color: "black" }} to="/projetos-concluidos">
            <RiArrowLeftSLine size="53" />
          </Link>
          Victor
        </HeadStyle>
        <ParagraphStyle>{t("TxtVictor")}</ParagraphStyle>
        <HeadStyle weight="semibold" size="5">
          {t("ProjPublicacoes")}
        </HeadStyle>
        <ReferenceStyle>
          Braz, N. et al. Document classification using a Bi-LSTM to unclog
          Brazil's supreme court. NIPS 2018 Workshop on Machine Learning for the
          Developing World (ML4D). arXiv:1811.11569
        </ReferenceStyle>
        <ReferenceStyle>
          Silva, N. et al.Document type classification for Brazil’s supreme
          court using a Convolutional Neural Network. THE INTERNATIONAL
          CONFERENCE ON FORENSIC COMPUTER SCIENCE AND CYBER LAW (ICoFCS). 2018.​
        </ReferenceStyle>
        <ReferenceStyle>
          Silva, N. Notas Iniciais Sobre a Evolução dos Algoritmos do Victor: O
          Primeiro Projeto de Inteligência Artificial em Supremas Cortes do
          Mundo. II Congresso Internacional de Direito, Governo e Tecnologia.
          2018.
        </ReferenceStyle>
        <a href="/projetos-concluidos/victor/lrec2020">
          <ButtonStyle color="black" rounded={true}>
            Dataset
          </ButtonStyle>
        </a>
      </div>
    </Container>
  );
}

export default Victor;
