import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/lab.png";
import us from "../assets/US.png";
import br from "../assets/BR.png";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

function NavComponent() {
  const { t, i18n } = useTranslation();
  const [, setLang] = useState("pt");

  const handleChangeLang = (lang) => {
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Fragment>
      <Navbar bg="white" expand="lg" sticky="top">
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              className="d-inline-block align-top"
              alt="Logo AI.lab"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="navbar-nav ml-auto">
            <Nav.Link as={HashLink} smooth to="/#home" color="dark">
              {t("Inicio")}
            </Nav.Link>
            {/* <a className="nav-link" href="https://forum.ailab.unb.br">
              {t("Forum")}
            </a> */}
            <NavDropdown title={t("Projetos")} style={{ display: "block" }}>
              <NavDropdown.Item as={Link} to="/projetos-andamento">
                {t("Em andamento")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/projetos-concluidos">
                {t("Concluídos")}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={HashLink} smooth to="/noticias" color="dark">
              {t("Noticias")}
            </Nav.Link>
            <Nav.Link as={Link} to="/equipe" color="dark">
              {t("Equipe")}
            </Nav.Link>
            <Nav.Link as={Link} to="/editais" color="dark">
              {t("Editais")}
            </Nav.Link>
            <Nav.Link as={HashLink} smooth to="/#parcerias" color="dark">
              {t("Parcerias")}
            </Nav.Link>
            <NavDropdown title="Datasets" style={{ display: "block" }}>
              <NavDropdown.Item as={Link} to="/datasets">
                {t("Todos")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/victor/lrec2020">
                Victor
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={HashLink} smooth to="https://niajus.org/service/pesquisa/" color="dark" rel="noopener noreferrer" target="_blank">
              {"NiaJus"}
            </Nav.Link>
            <Nav.Link>
              <img
                onClick={(e) => handleChangeLang("pt")}
                src={br}
                alt="Bandeira Brasil"
                height="30"
                width="30"
              />
            </Nav.Link>
            <Nav.Link>
              <img
                onClick={(e) => handleChangeLang("en")}
                src={us}
                alt="Bandeira USA"
                height="30"
                width="30"
              />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
}

export default NavComponent;
