import React from "react";
import { Container, Heading } from "react-bulma-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";


const HeadStyle = styled(Heading)`
  margin: 50px 80px;
  text-align: justify;
`;

const ParagraphStyle = styled.p`
  text-align: justify;
  margin: 35px 70px 50px;
`;

function Lavrador() {
  const { t } = useTranslation();

  return (
    <Container>
      <div>
        <HeadStyle weight="light" size="1">
        <Link style={{color:'black'}} to="/projetos-concluidos"><RiArrowLeftSLine size="53"/></Link>
          Lavrador
        </HeadStyle>
        <ParagraphStyle>{t("TxtLavrador")}</ParagraphStyle>
      </div>
    </Container>
  );
}

export default Lavrador;
