// Imports
import React from "react";
import {
  Heading,
  Container,
  Image,
  Button,
  Level,
  Box,
} from "react-bulma-components";

import styled from "styled-components";
import { news } from "../../data/news";
import uuid from "react-uuid";

// Styled components
const HeadStyle = styled(Heading)`
  text-align: justify;
  font-size: 40px;
  margin-top: 10px;
`;

const HeadNews = styled.p`
  font-size: 20px;
`;

const CardPosition = styled.div`
  display: flex;
  text-align: left;
`;

const ButtonStyle = styled(Button)`
  margin-top: 10px;
  margin-left: 20px;
`;

const ContentStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 20px;
`;

const ImagePosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line no-unused-vars
const NewsInEvidence = styled.article`
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px;
`;

const Scroll = styled.div`
  padding: 0 10px 10px;
  overflow-y: scroll;
  height: 600px;

  ::-webkit-scrollbar {
    background-color: aliceblue;

    color: black;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #293B7E;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darkblue;
  }
`;

function News() {
  return (
    // All components inside a container
    <Container>
      <HeadStyle>Notícias</HeadStyle>
      {/* Noticias que precisam de destaque, colocar como filha do componente abaixo */}
      {/* <NewsInEvidence>

      </NewsInEvidence> */}

      <Scroll>
        {news.map((n) => {
          return (
            <Box key={uuid()}>
              <CardPosition>
                <ImagePosition>
                  <Image size={128} alt="128x128" src={n.logo} />
                </ImagePosition>
                <ContentStyle>
                  <HeadNews>
                    <strong>{n.origin}</strong>
                  </HeadNews>
                  <p style={{ whiteSpace: "break-spaces" }}>{n.description}</p>

                  <Level breakpoint="mobile">
                    <Level.Side align="left">
                      <a href={n.link} style={{ textDecoration: "none" }}>
                        <ButtonStyle
                          rounded={true}
                          outlined={true}
                          color="dark"
                        >
                          Saiba Mais
                        </ButtonStyle>
                      </a>
                    </Level.Side>
                  </Level>
                </ContentStyle>
              </CardPosition>
            </Box>
          );
        })}
      </Scroll>
    </Container>
  );
}

export default News;
