import React from "react";
import Home from "../components/Home/index";
import Team from "../components/Team/index";
import News from "../components/News/index";
import Projects from "../components/Projects/index";
import Datasets from "../components/Datasets/index";
import VictorProject from "../components/Projects/Victor/index";
import Lesao from "../components/Projects/Lesao-de-pele/index";
import Pe from "../components/Projects/Pe-diabetico/index";
import Mamografia from "../components/Projects/Mamografia/index";
import Lavrador from "../components/Projects/Lavrador/index";
import Victor from "../components/Datasets/DatasetVictor";
import Alei from "../components/Projects/Alei/index";
import Mandamus from "../components/Projects/Mandamus/index";
import Notices from "../components/Notices";
import { ProjectsInProgress } from "../components/ProjectsInProgress";
import Just40 from "../components/ProjectsInProgress/Justica40";
import Sabia from "../components/ProjectsInProgress/Sabia";
import Alei1a from "../components/ProjectsInProgress/Alei1a";
import Osiris from "../components/ProjectsInProgress/Osiris";

export const PublicRoutes = [
  {
    index: true,
    name: "Home",
    element: <Home />,
  },
  {
    path: "equipe",
    name: "Equipe",
    element: <Team />,
  },
  {
    path: "noticias",
    name: "Noticias",
    element: <News />,
  },
  {
    path: "projetos-concluidos",
    name: "Projetos Concluídos",
    element: <Projects />,
  },
  {
    path: "projetos-concluidos/lesao-de-pele",
    name: "Lesão de Pele",
    element: <Lesao />,
  },
  {
    path: "projetos-concluidos/pe-diabetico",
    name: "Pé Diabético",
    element: <Pe />,
  },
  {
    path: "mamografia",
    name: "Mamografia",
    element: <Mamografia />,
  },
  {
    path: "projetos-concluidos/lavrador",
    name: "Lavrador",
    element: <Lavrador />,
  },
  {
    path: "projetos-concluidos/alei",
    name: "A Lei",
    element: <Alei />,
  },
  {
    path: "projetos-concluidos/mandamus",
    name: "Mandamus",
    element: <Mandamus />,
  },
  {
    path: "projetos-concluidos/victor",
    name: "Victor",
    element: <VictorProject />,
  },
  {
    path: "projetos-andamento",
    name: "Projetos em Andamento",
    element: <ProjectsInProgress />,
  },
  {
    path: "projetos-andamento/justica-40",
    name: "Justiça 4.0",
    element: <Just40 />,
  },
  {
    path: "projetos-andamento/sabia",
    name: "SABIA",
    element: <Sabia />,
  },
  {
    path: "projetos-andamento/alei-1a",
    name: "A Lei 1a",
    element: <Alei1a />,
  },
  {
    path: "projetos-andamento/osiris",
    name: "OSIRIS",
    element: <Osiris />,
  },

  {
    path: "editais",
    name: "Editais",
    element: <Notices />,
  },
  {
    path: "datasets",
    name: "Datasets",
    element: <Datasets />,
  },
  {
    path: "victor/lrec2020",
    name: "Victor",
    element: <Victor />,
  },
];
