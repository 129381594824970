import React from "react";

import { Footer, Container, Hero } from "react-bulma-components";
import { AiOutlineInstagram } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import { FaLinkedin, FaSpotify } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import logo from "../assets/logo_lab.png";
import "./styles.css";

function FooterComponent() {
  const year = new Date().getFullYear();
  return (
    <Hero size="small">
      <Hero.Head renderAs="header" />
      <Hero.Body />
      <Hero.Footer>
        <Footer>
          <Container className="footer_container">
            <Container className="footer_text">
              <img className="footer-logo" src={logo} alt="AI.Lab" />
            </Container>
            <Container className="footer_icons">
              <tr>
                <td>
                  <a href="https://www.instagram.com/ailab_unb/">
                    <AiOutlineInstagram size="30" color="#c13584" /> @ailab_unb
                  </a>
                </td>
                <td>
                  <a href="https://www.linkedin.com/company/ailab-unb/">
                    <FaLinkedin size="30" color="#0e76a8" /> AI.lab UnB
                  </a>
                </td>
                <td>
                  <a href="#/">
                    <MdEmail size="30" /> ailabunb@gmail.com
                  </a>
                </td>
                {/* <td>
                  <a href="https://twitter.com/ailab_unb">
                    <FiTwitter size="30" color="#1DA1F2" /> @ailab_unb
                  </a>
                </td> */}
                <td>
                  <a href="https://open.spotify.com/show/5zG1wJ7hXsY1MjYjEPbK1B">
                    <FaSpotify size="30" color="#1ed760" /> Spotify
                  </a>
                </td>
              </tr>
            </Container>
          </Container>
          <Container>
            <p className="final">©{year} por AI.lab UnB.</p>
          </Container>
        </Footer>
      </Hero.Footer>
    </Hero>
  );
}

export default FooterComponent;
