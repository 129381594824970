//imports
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

//style imports
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

//images imports
import Logo1 from "../assets/Parcerias/biotic.jpg";
import Logo2 from "../assets/Parcerias/cnj.jpg";
import Logo3 from "../assets/Parcerias/fap_df.jpg";
import Logo4 from "../assets/Parcerias/pgdf.jpg";
import Logo5 from "../assets/Parcerias/pnud.jpg";
import Logo6 from "../assets/Parcerias/TST.png";
import Logo7 from "../assets/Parcerias/logo-trf1.svg";
import Logo8 from "../assets/Parcerias/logo-finatec.svg";
import Logo9 from "../assets/Parcerias/logo-drIA.svg";


// Video imports
import BannerAilabVideo from "../assets/videos/banner.mp4";

import CarouselComponent from "../common/Carousel";
import { useTranslation } from "react-i18next";

import { news } from "../../data/news.js";

// news data
import publications from "../../data/publications.json";
import { ailab } from "../../data/ailab";

function Home() {
  const { t } = useTranslation();

  const ailabImages = ailab.map((img) => img.image);

  const images = news
    .map((n) => n.backgroundImage)
    .reverse()
    .slice(0, 3);

  const desc = news
    .map((n) => n.description)
    .reverse()
    .slice(0, 3);

  const carprop = {
    img: images,
    title: t("Noticias"),
    caption: desc,
    p: [
      <Button
        variant="outline-light"
        className="link"
        onClick={() => window.location.replace("/noticias")}
      >
        {t("VerMais")}
      </Button>,
      <Button
        variant="outline-light"
        onClick={() => window.location.replace("/noticias")}
      >
        {t("VerMais")}
      </Button>,
      <Button
        variant="outline-light"
        onClick={() => window.location.replace("/noticias")}
      >
        {t("VerMais")}
      </Button>,
    ],
  };

  const ailabCarouselProp = {
    img: ailabImages,
  };

  const videoRef = useRef(null);
  const spanRef = useRef(null);

  useEffect(() => {
    const handleScrollOrClick = () => {
      if (videoRef.current) {
        videoRef.current.muted = true;  // Mute the video
        videoRef.current.play().catch((error) => {
          console.error("Autoplay was prevented:", error);
        });
      }
    };
  
    // Add scroll and click event listeners
    window.addEventListener('scroll', handleScrollOrClick);
    window.addEventListener('click', handleScrollOrClick);
  
    return () => {
      window.removeEventListener('scroll', handleScrollOrClick);
      window.removeEventListener('click', handleScrollOrClick);
    };
  }, []);
  
  useEffect(() => {
    const videoEl = videoRef.current;
  
    if (!videoEl) {
      return;
    }
  
    const span = document.getElementById("videoSpan");
  
    const updateSpanPosition = () => {
      if (span && videoEl) {
        console.log(videoEl.clientHeight);
        span.style.transform = `translateY(${-(videoEl.clientHeight / 3)}px)`;
      }
    };
  
    // Ensure video metadata is loaded to get correct dimensions
    videoEl.addEventListener('loadedmetadata', updateSpanPosition);
    
    // Update span position immediately in case metadata is already loaded
    updateSpanPosition();
  
    // Cleanup event listener
    return () => {
      videoEl.removeEventListener('loadedmetadata', updateSpanPosition);
    };
  }, [videoRef]);


  return (
    <div id="home">

      <div className="video-banner">
        <video id="bannerAilabVideo" style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
          ref={videoRef}
          muted
          playsInline
          autoPlay
          loop
        >
          <source src={BannerAilabVideo} type="video/mp4" />
        </video>
        <span
        id="videoSpan"
        ref={spanRef}
        >{t("Info")}</span>

      </div>

      <div className="container-margin">
        <div className="container span-text">
            {t("AilabDesc")}
        </div>
      </div>


      <div className="container-margin">
        <Container className="lastNews">
          <h1>{t("Publicacoes")}</h1>

          <div className="container-margin">
            <Table responsive size="sm">
              <tbody>
                {publications.publications.map((publication) => {
                  return (
                    <tr key={publication.description}>
                      <td className="align-middle">{publication.origin}</td>
                      <td className="align-middle">
                        <strong>{publication.description}</strong>
                      </td>
                      <td className="align-middle">{publication.authors}</td>
                      <td className="align-middle">
                        <Button href={publication.url} variant="outline-dark">
                          {t("Acesso")}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="container-margin lattes">
            <h2>{t("PublicacoesT")}</h2>

            <p className="par-lattes">{t("PublicacoesTexto")}</p>
            <div className="container-link-lattes ">
              <a href="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4779693P1">
                <Button variant="dark">Lattes Nilton Silva</Button>
              </a>
              <a href="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4765736Y3">
                <Button variant="dark">Lattes Fabrício Braz</Button>
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div id="noticias">
        <br />
      </div>
      <div className="container-margin">
        <div className="caroulsel-container container">
          <CarouselComponent props={carprop} />
        </div>
      </div>

      <Container className="rowTeam">
        <Row>
          <div className="TeamText">
            <h1>{t("ConhecaTime")}</h1>
            <p style={{ fontSize: 22 }}>{t("TimeMsg")}</p>
            <Link to="/equipe">
              <Button variant="outline-dark">{t("SaibaMais")}</Button>
            </Link>
          </div>
          <div className="carousel-position-ailab">
            <CarouselComponent props={ailabCarouselProp} size={"small"} />
          </div>
        </Row>
      </Container>

      <div id="parcerias">
        <Row>
          <Col className="logos">
            <h1>{t("Parcerias")}</h1>
            <div className="img-logo">
              <img src={Logo1} width="200" height="200" alt="" />
              <img src={Logo2} width="300" height="300" alt="" />
              <img src={Logo3} width="300" height="300" alt="" />
              <img src={Logo4} width="300" height="300" alt="" />
              <img src={Logo5} width="300" height="300" alt="" />
              <img src={Logo6} width="300" height="300" alt="" />
              <img src={Logo7} width="300" height="300" alt="" />
              <img src={Logo8} width="300" height="300" alt="" />
              <img src={Logo9} width="200" height="200" alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Home;
