import React from "react";
import { Container } from "react-bulma-components";

import Coordinartion from "./coordination";
import Management from "./reseachers";

import { useTranslation } from "react-i18next";

/**
 * This file define the team page of the ailab webpage
 */

function Team() {
  const { t } = useTranslation();
  return (
    <Container>
      <div>
        <header style={headerStyle}>{t("Equipe")}</header>
        <p style={textStyle}>{t("EquipeTxt")}</p>
        <div>
          <Coordinartion />
          <Management />
        </div>
      </div>
    </Container>
  );
}

//Introductory text CSS
const textStyle = {
  textAlign: "left",
  margin: "20px",
  fontSize: "18px",
};

//Header CSS
const headerStyle = {
  fontSize: "40px",
  textAlign: "left",
  margin: "20px",
};

export default Team;
