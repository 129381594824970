import LogoTRF from "../components/assets/TRF_logo.webp";
import TRF from "../components/assets/TRF_logo.webp";
import FGA from "../components/assets/Home/fga.jpg";
import TRF6 from "../components/assets/logo-trf6.svg";
import Globo from "../components/assets/bom_dia_df_logo.webp";
import Alei2 from "../components/assets/alei_2.jpeg";
import TRF3 from "../components/assets/trf3.webp";
import Ia from "../components/assets/ia.jpg";
import CapacitacaoAlei from "../components/assets/capacitacao_alei.webp";
import Finatec from "../components/assets/Parcerias/logo-finatec.svg";
import Alei from "../components/assets/alei.webp";
import LogoAlei from "../components/assets/alei_logo.webp";
import ProjAlei from "../components/assets/proj_alei.webp";
import Webinario from "../components/assets/webinario.webp";
import Webinario2 from "../components/assets/webinario2.webp";
import Stf from "../components/assets/stf_logo.webp";
import ExecFiscalCnj from "../components/assets/exec_fiscal_cnj.webp";
import Unb from "../components/assets/unb.png";

export const news = [
  {
    origin: "AiLab no EGPA 2024",
    logo: "https://media.licdn.com/dms/image/v2/D4D22AQFwFseVuvLPTw/feedshare-shrink_800/feedshare-shrink_800/0/1720016641164?e=1728518400&v=beta&t=FkA5IMlNxWyiDP_NCSIL98UaGalZwrnJ4JTT821CZbM",
    link: "https://www.linkedin.com/posts/ailab-unb_academia-pesquisa-justiaexa-activity-7214272684342321152-kNch/",
    description: `📚🌐 AiLab no EGPA 2024 🚀

É com grande satisfação que compartilhamos uma atualização sobre o nosso trabalho no campo do desenvolvimento de Inteligência Artificial (IA) na administração de justiça e tribunais. 

Recentemente, o AiLab submeteu artigos para a conferência EGPA 2024, especificamente na trilha do Grupo de Estudo Permanente 18 - Justiça e Administração de Tribunais, sob a coordenação dos professores Dr. Fabricio Braz e Dr. Nilton Silva. 

Estamos orgulhosos em anunciar que três desses artigos, sobre as soluções PEDRO e ALEI-1G, foram aceitos para publicação! 🎉 

A EGPA (European Group for Public Administration) acontecerá em Atenas, Grécia, de 3 a 6 de setembro de 2024, com o tema “Fortalecendo a Governança Democrática para Melhores Políticas e Serviços Públicos”. É um evento de prestígio que reúne especialistas de todo o mundo para debater os desafios, avanços e perspectivas na administração pública. Participar dessa conferência e contribuir com pesquisas sobre IA é uma oportunidade inestimável para expandir nossas redes, capacidades e compartilhar conhecimentos. 

Agradecemos a todos os pesquisadores e coautores que tornaram essas submissões possíveis, desde as hipóteses de pesquisa até a atuação no desenvolvimento e validação das soluções. Seguimos em frente, ansiosos para compartilhar nossas descobertas com a comunidade global. A inovação é o nosso propósito. 🌐💡 

Conferência: https://lnkd.in/efaKgx_J
Trilha: https://lnkd.in/dwmGuz9C

#Academia #Pesquisa #Justiça #AdministraçãoDeTribunais #EGPA2024 #AdministraçãoPública #Inovação #Colaboração #InteligenciaArtificial #MachineLearning`,
  },
  {
    origin: "Palestra do Prof. Dr. Nilton Silva na Campus Party BSB 2024",
    logo: "https://media.licdn.com/dms/image/sync/v2/D4D27AQE6tVD19jKTog/articleshare-shrink_160/articleshare-shrink_160/0/1711974574666?e=1726002000&v=beta&t=ppOG6hvdXLakhrbHqiN0Kap5vBf8Z1Ehtar-iRhRCCk",
    link: "https://www.linkedin.com/posts/ailab-unb_cpbsb6-prof-nilton-activity-7180542527958093824-OkdF",
    description: `Palestra do Prof. Dr. Nilton Silva, na Campus Party BSB 2024, sobre Processamento de Linguagem Natural. 👨‍💻 

Veja na íntegra: https://lnkd.in/dRSeW86X

Vem conferir e nos contar quais são as suas perspectivas sobre o assunto.. 🌐 😉 

hashtag#CPBSB6 hashtag#BSB hashtag#IA 29/03/2024`
  },
  {
    origin: "AiLab na NeurIPS",
    logo: "https://upload.wikimedia.org/wikipedia/en/0/08/Logo_for_Conference_on_Neural_Information_Processing_Systems.svg",
    link: "https://www.instagram.com/p/C0wYV5nssrW/?igsh=MW9pYzhyOXFoeHNjNw==",
    description: `🚀 Estamos na NeurIPS, a principal conferência mundial de #machinelearning e #artificialintelligence !

🧠 Aqui, os maiores avanços da área são revelados, com Deep Learning, GANs e Reinforcement Learning dominando as discussões teóricas e práticas.
📚 Dezenas de workshops, sessões e apresentações estão acontecendo, trazendo à tona inovações incríveis. Nos workshops, mergulhamos a fundo em tópicos específicos e conhecemos inovações antes mesmo de serem oficialmente publicadas.
🌐 Grandes empresas de tecnologia não perdem a chance de observar mentes brilhantes e conhecer as últimas tendências. Imagina “esbarrar” pelos corredores com os gigantes da pesquisa em IA?
🤖 Um dos pontos altos do evento até agora foi a palestra da “lenda” da IA, Andrew Yan-Tak Ng. Ng foi cofundador e chefe do Google Brain e ex-cientista-chefe da Baidu, transformou o Grupo de Inteligência Artificial da empresa em uma equipe de vários milhares de pessoas. Hoje é professor adjunto da Universidade Stanford, empresário da área de tecnologia e um dos cientistas da computação mais famosos e influentes do mundo.
💡 Com mais de mil artigos na conferência principal, é um desafio acompanhar tudo, mas estar nesse ambiente inspirador garante novos insights a cada minuto.
👥 Além do foco acadêmico, o NeurIPS é puro networking! Festas e encontros descontraídos aproximam pessoas com interesses similares e conhecimentos complementares. A IA não é só sobre algoritmos.
🤖Voltaremos com muito material para P&D aplicável ao cenário dos nossos projetos e novas perspectivas..

O evento acontece em New Orleans, de 10 a 16/12.
https://neurips.cc/Conferences/2023

#NeurIPS #MachineLearning #IA #Inovação #Networking #ailab`

  },
  {
    origin: "Workshop de Inteligência Artificial na UnB",
    logo: Unb,
    link: "https://sigaa.unb.br/sigaa/link/public/extensao/visualizacaoAcaoExtensao/9535",
    description:
      "O Departamento de Ciência da Computação da Universidade de Brasília (CIC – UnB) está organizando um Workshop de Inteligência Artificial. Este workshop tem como objetivo proporcionar a estudantes, profissionais e entusiastas uma oportunidade única de mergulhar no domínio dinâmico da IA ​​e obter insights sobre as pesquisas inovadoras realizadas na UnB.",
  },
  {
    origin:
      "Integração dos sistemas de consulta patrimonial à PDPJ-Br agiliza execução fiscal",
    logo: ExecFiscalCnj,
    link: "https://www.cnj.jus.br/integracao-dos-sistemas-de-consulta-patrimonial-a-pdpj-br-agiliza-execucao-fiscal/",
    description:
      "O uso de tecnologias da informação, do desenvolvimento de sistemas com aplicação de inteligência artificial e a maior participação dos cartórios de protesto para evitar a judicialização na recuperação de dívidas estão entre as soluções apontadas por especialistas para uma maior eficiência na execução fiscal.",
  },
  {
    origin: "2º Ciclo de Empreendedorismo do NIAJus",
    logo: "https://niajus.org/wp-content/uploads/elementor/thumbs/Niajus-logo-id-55-q7xi34blq2kq8v2wyhyltspoaqoowdybkwazqo65c4.png",
    link: "https://niajus.org/empreendedorismo-detalhes/",
    description: `📣 Anúncio: 2º Ciclo de Empreendedorismo do NIAJus 📣

🚀 Está pronto para dar um grande passo em direção ao sucesso da sua legaltech?

🗓️ Inscrições: 04/10/2023 a 15/10/2023  
📍 Local: Online

📝 Inscreva-se agora e faça parte deste ciclo de aprendizado e crescimento! Acesse: [niajus.org/empreendedorismo-detalhes](https://niajus.org/empreendedorismo-detalhes)

✨ Explore oportunidades ilimitadas, adquira conhecimento valioso e conecte-se com empreendedores inspiradores.

✔️ Imersão prática em conceitos de inovação e empreendedorismo, incluindo:
- Entendimento de problema  
- Descoberta de mercado-alvo  
- Segmentação de clientes ideais  
- Estruturação de modelo de negócio  
- Desenvolvimento de produto  
- Montagem de times  
- Encaixe de problema e solução  
- Estruturação de vendas  
- Preparação para captação de investimento

💼💡 Junte-se a nós e transforme suas ideias em realidade!

#Empreendedorismo #NIAJus #Inovação #Networking #Sucesso
    `,
  },
  {
    origin: "VitórIA é a terceira inteligência artificial (IA) usada no STF",
    logo: Stf,
    link: "https://www.convergenciadigital.com.br/Inovacao/VitorIA-e-a-terceira-inteligencia-artificial-%28IA%29-usada-no-STF-63198.html",
    description:
      "O Supremo Tribunal Federal (STF) está finalizando a fase de testes para o lançamento de uma nova ferramenta de Inteligência Artificial (IA). Batizada de VitórIA, a plataforma vai ampliar o conhecimento sobre o perfil dos processos recebidos no STF e permitir o tratamento conjunto de temas repetidos ou similares.",
  },
  {
    origin:
      '"IA aplicada ao Judiciário sob a ótica acadêmica": professores de referência internacional ministram palestra em webinário',
    logo: Webinario2,
    link: "https://www.tjgo.jus.br/index.php/institucional/centro-de-comunicacao-social/17-tribunal/26727-webinario-aborda-a-inteligencia-artificial-ia-aplicada-ao-judiciario-sob-a-otica-academica",
    description:
      "O encontro foi realizado pela Ejug, com transmissão pelo canal da Ejug no Youtube.",
  },
  {
    origin:
      "Inteligência Artificial Aplicada ao Judiciário sob a ótica acadêmica",
    logo: Webinario,
    link: "https://www.tjgo.jus.br/index.php/institucional/centro-de-comunicacao-social/17-tribunal/26696-inteligencia-artificial-aplicada-ao-judiciario-sob-a-otica-academica-e-tema-de-webinario-na-proxima-segunda-feira-5",
    description:
      "Nesta edição, iremos trazer especialistas que abordarão a Inteligência Artificial na perspectiva acadêmica, o que vai impactar no aprendizado, na formação dos novos profissionais de TI, e, também dos novos profissionais de Direito.",
  },
  {
    origin: "Justiça Federal",
    logo: TRF,
    link: "https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-trf1-e-stf-trocam-experiencias-sobre-o-uso-da-inteligencia-artificial-no-poder-judiciario.htm",
    description:
      "TRF1 e STF trocam experiências sobre o uso da Inteligência Artificial no Poder Judiciário.",
    backgroundImage: "",
  },
  {
    origin: "Bom Dia DF",
    logo: Globo,
    link: "https://globoplay.globo.com/v/8009615/",
    description:
      "Laboratório na UnB, criado em parceria com o STF, ajuda na informatização de processos.",
    backgroundImage: "",
  },
  {
    origin: "Justiça Federal",
    logo: TRF,
    link: "https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-justica-federal-da-1-regiao-cresce-em-indice-de-governanca-gestao-e-infraestrutura-de-tecnologia-da-informacao-e-comunicacao.htm",
    description:
      "Justiça Federal da 1ª Região cresce em Índice de Governança, Gestão e Infraestrutura de Tecnologia da Informação e Comunicação",
    backgroundImage: "",
  },
  {
    origin: "UnB Notícias",
    logo: FGA,
    link: "https://www.noticias.unb.br/76-institucional/3384-unb-gama-inaugura-laboratorio-de-inteligencia-artificial",
    description: "UnB Gama inaugura Laboratório de Inteligência Artificial.",
    backgroundImage: FGA,
  },
  {
    origin: "Justiça Federal",
    logo: Alei2,
    link: "https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-trf1-e-unb-firmam-parceria-para-desenvolvimento-da-ferramenta-de-inteligencia-artificial-alei.htm",
    description:
      "TRF1 e UnB firmam parceria para desenvolvimento da ferramenta de Inteligência Artificial",
    backgroundImage: TRF,
  },
  {
    origin: "JusBrasil",
    logo: TRF6,
    link: "https://eduardobonifaciobatista.jusbrasil.com.br/noticias/1639619277/trf6-equipe-tecnica-do-trf6-participa-de-ultimo-encontro-de-apresentacao-do-sistema-alei",
    description:
      "TRF6 - Equipe técnica do TRF6 participa de último encontro de apresentação do sistema ALEI.",
    backgroundImage: TRF6,
  },
  {
    origin: "Justiça Federal",
    logo: LogoAlei,
    link: "https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-trf1-realiza-encontros-para-demonstracao-do-sistema-analise-legal-inteligente-alei.htm",
    description:
      "TRF1 realiza encontros para demonstração do Sistema Análise Legal Inteligente - ALEI",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF3",
    logo: TRF3,
    link: "https://web.archive.org/web/20221213190141/https:/web.trf3.jus.br/noticias-intranet/Noticiar/ExibirNoticia/401859-trf3-apresenta-projetos-de-inteligencia-artificial",
    description:
      "TRF3 APRESENTA PROJETOS DE INTELIGÊNCIA ARTIFICIAL A REPRESENTANTES DO TRF1",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: Ia,
    link: "https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-artigo-baseado-no-projeto-alei-e-publicado-na-sciencedirect.htm",
    description: "Artigo baseado no projeto Alei é publicado na ScienceDirect",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: CapacitacaoAlei,
    link: "https://web.archive.org/web/20221213185338/https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-capacitacao-sobre-o-alei-do-trf1-foca-em-entender-e-utilizar-a-inteligencia-artificial-para-aprimorar-triagem-e-julgamento-de-processos.htm",
    description:
      "Capacitação sobre o ALEI do TRF1 foca em entender e utilizar a inteligência artificial para aprimorar triagem e julgamento de processos",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: TRF,
    link: "https://web.archive.org/web/20221213192215/https:/portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-reuniao-da-rede-de-inteligencia-da-1-regiao-apresenta-modelo-de-negocio-do-alei-e-nova-fase-do-projeto.htm",
    description:
      "Reunião da Rede de Inteligência da 1ª Região apresenta modelo de negócio do Alei e nova fase do projeto",
    backgroundImage: LogoTRF,
  },
  {
    origin: "Finatec",
    logo: Finatec,
    link: "https://web.archive.org/web/20221213192843/https:/www.finatec.org.br/noticia/projeto-alei-promove-curso-de-capacitacao-para-servidores-do-trf1/",
    description:
      "Projeto ALEI promove curso de capacitação para servidores do TRF1",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: Alei,
    link: "https://web.archive.org/web/20221213184452/https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-ferramenta-alei-comeca-a-ser-disponibilizada-aos-gabinetes-do-trf1.htm",
    description:
      "Ferramenta ALEI começa a ser disponibilizada aos Gabinetes do TRF1",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: Alei,
    link: "https://web.archive.org/web/20221213191448/https:/portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-trf1-recebe-equipe-tecnica-do-trf-6-regiao-no-ultimo-encontro-para-familiarizar-usuarios-ao-sistema-alei.htm",
    description:
      "TRF1 recebe equipe técnica do TRF 6ª Região no último encontro para familiarizar usuários ao Sistema ALEI",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: LogoAlei,
    link: "https://web.archive.org/web/20221213192451/https:/portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-institucional-participe-do-segundo-dia-da-capacitacao-do-sistema-de-analise-inteligente-alei-do-trf-1-regiao.htm",
    description:
      "Participe do segundo dia da capacitação do Sistema de Análise Inteligente – ALEI do TRF 1ª Região",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: ProjAlei,
    link: "https://web.archive.org/web/20221213184240/https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-projeto-analise-legal-inteligente-alei-e-apresentado-ao-presidente-do-trf-1-regiao-e-equipe.htm",
    description:
      "Projeto Análise Legal Inteligente (Alei) é apresentado ao Presidente do TRF 1ª Região e equipe",
    backgroundImage: LogoTRF,
  },
  {
    origin: "TRF1",
    logo: ProjAlei,
    link: "https://web.archive.org/web/20221213183330/https://portal.trf1.jus.br/portaltrf1/comunicacao-social/imprensa/noticias/institucional-sistema-de-inteligencia-artificial-que-agiliza-analise-de-processos-sera-apresentado-em-sessao-plenaria-extraordinaria-a-desembargadores-federais-do-trf1.htm",
    description:
      "Sistema de inteligência artificial que agiliza análise de processos será apresentado em sessão plenária extraordinária a desembargadores federais do TRF1",
    backgroundImage: LogoTRF,
  },
];
