import styled from "styled-components";
import {
  Container,
  Content,
  Heading,
  Columns,
  Button,
} from "react-bulma-components";

export const ColumnStyle = styled(Columns.Column)`
  padding: 30px;
  color: white;
  margin-bottom: 30px;
`;

export const ContentWrapper = styled(Container)`
  display: flex;
  flex-direction: row;
`;

export const ContentStyle = styled(Content)`
  text-align: justify;
  height: 170px;
  overflow: hidden;
`;

export const ButtonStyle = styled(Button)`
  text-align: justify;
`;

export const HeadStyle = styled(Heading)`
  text-align: justify;
  font-size: 40px;
  margin-left: 10px;
`;

export const ParagraphStyle = styled.p`
  text-align: justify;
  font-size: 20px;
  margin-bottom: 50px;
`;

//Div CSS definitions
export const divStyle = {
  // marginLeft: "50px",
  flex: 1,
  alignContentr: "stretch",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
};

export const cardStyle = {
  margin: "20px",
  maxWidth: "300px",
  width: "300px",
  height: "350px",
  maxHeight: "350px",
};
