import React, { Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { Carousel } from "react-bootstrap";
import uuid from "react-uuid";

const CarouselComponent = (props) => {
  const createCarousel = () => {
    let carousel = [];
    for (let i = 0; i < props.props.img.length; i++) {
      carousel.push(
        <Carousel.Item key={uuid()}>
          <img
            className="fixed-height"
            src={props.props.img[i]}
            alt={`Slide ${i}`}
          />
          <Carousel.Caption>
            {props.props.caption && (
              <>
                <h2>{props.props.caption[i]}</h2>
                <p id={`img${i + 1}`}>{props.props.p[i]}</p>
              </>
            )}
          </Carousel.Caption>
        </Carousel.Item>
      );
    }
    return carousel;
  };

  return (
    <Fragment>
      <h1 style={{ margin: 20 }}>{props.props.title}</h1>
      <Carousel id="carousel" className={props.size}>{createCarousel()}</Carousel>
    </Fragment>
  );
};

export default CarouselComponent;
