import React from "react";
import { Container } from "react-bulma-components";

function Victor() {
  return (
    <Container>
      <div>
        <header style={headerStyle}>LREC 2020 - VICTOR</header>
        <div>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSejEzsjU2-W-abPpkQ29GJ10hgpYvUgE_Dbf8qqLxwLMIW3pQ/viewform?embedded=true"
            style={{ overflow: "hidden", width: "800px", height: "2550px" }}
            frameborder="0"
            title="Victor"
          ></iframe>
        </div>
      </div>
    </Container>
  );
}

const headerStyle = {
  fontSize: "40px",
  textAlign: "left",
  margin: "25px",
};

export default Victor;
