import React from "react";

import notices2022 from "../../../../data/notices2022.json";
import {
  FaArrowCircleLeft,
  FaChevronCircleDown,
  FaChevronCircleRight,
} from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import uuid from "react-uuid";

export const Notices2022 = ({ setYear }) => {
  const [showNotice, setShowNotice] = React.useState(false);
  const [showInterview, setShowInterview] = React.useState(false);
  const [showApproved, setShowApproved] = React.useState(false);
  const [selectedNotice, setSelectedNotice] = React.useState(null);
  const [selectedInterview, setSelectedInterview] = React.useState(null);
  const [selectedApproved, setSelectedApproved] = React.useState(null);

  const handleDownloadFile = (file) => {
    if (file === "") return;
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = file;
        alink.click();
      });
    });
  };

  const extrairNomeArquivo = (caminho) => {
    const inicio = caminho.indexOf("-") + 1;
    const fim = caminho.lastIndexOf(".");
    return caminho.substring(inicio, fim).trim();
  };

  const toggleNotice = (notice) => {
    setSelectedNotice(notice);
    setShowNotice(!showNotice);
  };

  const toggleInterview = (interview) => {
    setSelectedInterview(interview);
    setShowInterview(!showInterview);
  };

  const toggleApproved = (approved) => {
    setSelectedApproved(approved);
    setShowApproved(!showApproved);
  };

  const noticesFiles = notices2022.notices.flatMap((notice) =>
    notice.documents.map((document) => {
      return {
        title: document.title,
        document: document.document,
        interview: document.interview,
        approved: document.approved,
      };
    })
  );

  return (
    <>
      <FaArrowCircleLeft style={arrowPosition} onClick={() => setYear("")} />
      {noticesFiles.map((notice) => {
        const uniqueId = notice.title;
        return (
          <Card key={uuid()} style={cardStyle}>
            <h2 style={textStyle}>
              {notice.title}
              <span onClick={() => toggleNotice(uniqueId)}>
                {!showNotice ? (
                  <FaChevronCircleRight />
                ) : (
                  <FaChevronCircleDown />
                )}
              </span>
            </h2>
            {showNotice && selectedNotice === uniqueId && (
              <Button
                key={notice.document}
                variant="outline-dark"
                onClick={() => handleDownloadFile(notice.document)}
              >
                {notice.title}
              </Button>
            )}

            <h3 style={subtitleStyle}>
              • Entrevistas{" "}
              <span onClick={() => toggleInterview(uniqueId)}>
                {!showInterview ? (
                  <FaChevronCircleRight />
                ) : (
                  <FaChevronCircleDown />
                )}
              </span>
            </h3>

            {showInterview &&
              selectedInterview === uniqueId &&
              notice.interview.map((interview) => {
                return (
                  <Button
                    key={interview}
                    variant="outline-dark"
                    onClick={() => handleDownloadFile(interview)}
                  >
                    {extrairNomeArquivo(interview)}
                  </Button>
                );
              })}

            <h3 style={subtitleStyle}>
              • Convocados{" "}
              <span onClick={() => toggleApproved(uniqueId)}>
                {!showApproved ? (
                  <FaChevronCircleRight />
                ) : (
                  <FaChevronCircleDown />
                )}
              </span>
            </h3>

            {showApproved &&
              selectedApproved === uniqueId &&
              notice.approved.map((approved) => {
                return (
                  <Button
                    key={approved}
                    variant="outline-dark"
                    onClick={() => handleDownloadFile(approved)}
                  >
                    {extrairNomeArquivo(approved)}
                  </Button>
                );
              })}
          </Card>
        );
      })}
    </>
  );
};

const arrowPosition = {
  display: "flex",
  cursor: "pointer",
  marginTop: "20px",
  marginBottom: "20px",
  fontSize: "20px",
};

const textStyle = {
  textAlign: "left",
  margin: "20px",
  fontSize: "18px",
  display: "flex",
  alignItems: "center",
  gap: "20px",
};

const subtitleStyle = {
  textAlign: "left",
  margin: "20px",
  fontSize: "18px",
  display: "flex",
  alignItems: "center",
  gap: "20px",
};

const cardStyle = {
  marginTop: "20px",
};
