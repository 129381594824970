import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "../../common/Nav";
import FooterComponent from "../../common/Footer";

export function BaseLayout() {
  return (
    <>
      <Nav />
      <Outlet />
      <FooterComponent />
    </>
  );
}
