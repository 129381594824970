import React from "react";
import Image from "./Image";

import { useTranslation } from "react-i18next";
import { team } from "../../data/team";

/**
 * Cordination function component
 */
function Management() {
  const { t } = useTranslation();

  return (
    <div>
      <p style={{ fontSize: "20px", margin: "25px" }}>{t("Coordenacao")}</p>
      <div style={style}>
        {team.map((coordinator, index) => {
          return (
            <div key={index} style={{ margin: "10px" }}>
              <Image
                link={coordinator.linkedin}
                image={coordinator.image}
                alt={coordinator.name}
                width="200px"
                height="200px"
                name={coordinator.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default Management;
