import Fabricio from "../components/assets/fabricio.webp";
import Nilton from "../components/assets/nilton.webp";
import Claudia from "../components/assets/Claudia.jpeg";
import Walquiria from "../components/assets/Pesquisadores/Walquiria.gif";
import Eduardo from "../components/assets/Pesquisadores/Eduardo.gif";
import BrunoRibas from "../components/assets/Pesquisadores/BrunoRibas.gif";
import AlineLemos from "../components/assets/Pesquisadores/AlineLemos.gif";
import JonathanLima from "../components/assets/Pesquisadores/JonathanLima.gif";
import Roitier from "../components/assets/Pesquisadores/Roitier.jpeg";
import LucianaNishi from "../components/assets/Pesquisadores/LucianaNishi.png";
import Adrielle from "../components/assets/Pesquisadores/Adrielle.jpeg";


export const team = [
  {
    name: "Fabricio Braz",
    linkedin: "https://www.linkedin.com/in/fabraz/",
    image: Fabricio,
  },
  {
    name: "Nilton Silva",
    linkedin: "https://www.linkedin.com/in/nilton-silva-6097853",
    image: Nilton,
  }
];

export const reseachers = [
  {
    name: "Eduardo Camargo de Siqueira",
    linkedin: "http://lattes.cnpq.br/1383558830607731",
    image: Eduardo
  },
  {
    name: "Bruno César Ribas",
    linkedin: "http://lattes.cnpq.br/6871745921667698",
    image: BrunoRibas
  },
  {
    name: "Roitier Campos Gonçalves",
    linkedin: "http://lattes.cnpq.br/4032967565186389",
    image: Roitier
  },
  {
    name: "Jonathan Alis Salgado Lima",
    linkedin: "http://lattes.cnpq.br/6686158583987853",
    image: JonathanLima
  },
  {
    name: "Aline Dayany de Lemos",
    linkedin: "http://lattes.cnpq.br/9407749848661234",
    image: AlineLemos
  },
  {
    name: "Luciana Nishi",
    linkedin: "http://lattes.cnpq.br/1699054697961149",
    image: LucianaNishi
  },
  {
    name: "Adrielle Beze Peixoto",
    linkedin: "http://lattes.cnpq.br/7262178944449081",
    image: Adrielle
  },
  {
    name: "Walquiria Fernandes Marins",
    linkedin: "http://lattes.cnpq.br/2022771202211940",
    image: Walquiria
  },
  {
    name: "Cláudia Jacy Barenco Abbas",
    linkedin: "http://lattes.cnpq.br/2433035330157054",
    image: Claudia
  }
];