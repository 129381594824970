const Pt = {
  translation: {
    Inicio: "Início",
    Forum: "Fórum",
    Projetos: "Projetos",
    Todos: "Todos",
    Noticias: "Notícias",
    Equipe: "Equipe",
    Editais: "Editais",
    Parcerias: "Parcerias",
    BemVindo: "BEM-VINDO AO AI.LAB",
    AilabDesc: "O AiLAB tem como missão promover a pesquisa, o desenvolvimento, o ensino e a extensão nas áreas de Inteligência Artificial e Ciência de Dados, ampliando sua inserção junto aos diversos setores da sociedade.",
    Info: "Laboratório de Inteligência Artificial da Universidade de Brasília",
    Intro:
      "O Laboratório de Inteligência Artificial tem como missão promover a pesquisa, o desenvolvimento, o ensino e a extensão nas áreas de Inteligência Artificial e Ciência de Dados, ampliando sua inserção junto aos diversos setores da sociedade. Utilizamos uma combinação de abordagens e tecnologias que desenvolvemos para usar em nossas investigações. Saiba mais sobre as nossas pesquisas e áreas de estudo abaixo.",
    Publicacoes: "Últimas Publicações",
    Acesso: "Acesso",
    PublicacoesTexto:
      "Aqui disponibilizamos as publicações mais recentes, mas você pode ver publicações anteriores pelo lattes!",
    PublicacoesT: "Gostaria de ver mais?",
    LabGama: "Gama inaugura Laboratório de Inteligência Artificial",
    TRF1: "TRF1 e UnB firmam parceria para desenvolvimento da ferramenta de Inteligência Artificial Alei",
    Ministra:
      "Ministra Cármen Lúcia anuncia início de funcionamento do Projeto Victor, de inteligência artificial",
    VerMais: "Ver mais",
    Resultado: "Resultado",
    Visualizar: "Visualizar",
    ConhecaTime: "Conheça o Time",
    TimeMsg: "Nada seria possível sem eles!",
    SaibaMais: "Saiba mais",
    EquipeTxt:
      "Grupo de Pesquisadores, Acadêmicos, Técnicos e interessados no desenvolvimento de pesquisas, tecnologias, extensões e ensino relacionados à área de Inteligência Artificial.",
    DatasetTxt: "Datasets utilizados na realização dos projetos do AiLab.",
    ProjetosTxt:
      "Projetos desenvolvidos pela equipe de pesquisadores e professores do AI.lab.",
    ProjVictor: "Projeto Victor",
    ResVictor: `Este projeto aplica métodos de aprendizado de máquina (machine
      learning) para reconhecer padrões nos processos jurídicos
      relativos a julgamentos de repercussão geral do Supremo
      Tribunal Federal - STF.`,
    TxtVictor: `O objeto de pesquisa e desenvolvimento (P&D) deste projeto é 
    aplicar métodos de aprendizado de máquina (machine learning) com o 
    objetivo de usar seus potenciais no processo de reconhecimento de 
    padrões nos processos jurídicos relativos a julgamentos de repercussão 
    geral do Supremo Tribunal Federal ‐ STF. Pragmaticamente, objetiva‐se
     o desenvolvimento de um sistema composto de algoritmos de aprendizagem 
     profunda de máquina que viabilize a automação de análises textuais destes 
     processos jurídicos. Isso será feito com a criação de modelos de machine 
     learning para análise dos recursos recebidos pelo Supremo Tribunal Federal 
     quanto aos temas de Repercussão Geral mais recorrentes, com objetivo de integrar 
     o parque de soluções do Tribunal para auxiliar os servidores responsáveis pela 
     análise dos recursos recebidos a identificar os temas relacionados.`,
    ProjLavrador: "Projeto Lavrador",
    ResLavrador: `Este projeto utiliza um dos melhores métodos de classificação
    de imagens por aprendizado de máquina (machine learning) para
    auxiliar na classificação de lavouras com imagens
    multiespectrais do satélite Landsat.`,
    TxtLavrador: `O projeto de pesquisa e desenvolvimento (P&D) Lavrador 
    surge para auxiliar a classificação de lavouras com imagens multiespectrais 
    do satélite Landsat. Foi aplicado um dos melhores métodos de classificação 
    de imagens por aprendizado de máquina (machine learning), as Redes Neurais 
    Convolucionais. O Lavrador permite classificar 7 diferentes tipos de lavouras 
    (Algodão, Arroz, Cana-de-açúcar, Laranja, Milho, Soja, Uva) e a classe "Outros" 
    baseando-se nas diferenças de assinaturas espectrais, com uma estrutura que permite a 
    aprendizagem de novos tipos de lavoura. O objetivo da pesquisa é contribuir no
     desenvolvimento agronômico com o auxílio de tecnologia para identificar lavouras 
     com imagens geoespaciais.`,
    ProjPe: "Pé Diabético",
    ResPe: `Este projeto busca ferramentas tecnológicas capazes de
    acompanhar a transformação da ferida do pé diabético e
    complementar o diagnóstico quanto à classificação da úlcera.`,
    TituloPe: `Classificação de úlcera do pé diabético usando aprendizagem de máquina aplicada a sequências de imagens`,
    TxtPe: `O projeto busca ferramentas tecnológicas capazes de conduzir a transformação da ferida do pé diabético e complementar o diagnóstico quanto à classificação da úlcera. Neste sentido, pretende-se desenvolver e avaliar um sistema automático para detecção e quantificação de aspectos centrais das úlceras do pé diabético (UPDs) utilizando técnicas de aprendizagem automática (Machine Learning), com base em imagens da ferida. Esses aspectos incluem granulação da lesão, presença ou ausência de fibrina, entre outros, quais são essenciais para determinar a severidade da úlcera. Espera-se que seja de fácil utilização a fim de ser manuseado pelos próprios pacientes e familiares, que serão capazes de gerar suas imagens e automaticamente, junto ao sistema poder identificar uma classificação para determinada ferida seguindo o protocolo de Classificação da Universidade do Texas. Ainda sendo possível futuramente, fornecer condições de alarme junto a uma estrutura integrada de dados de pacientes, profissionais ou hospitalares, permitindo que situações de risco sejam devidamente priorizadas.`,
    TxtPe2: `As imagens a seguir são pequenas amostras que serão utilizadas no trabalho, refere-se a uma lesão no pé diabético tratada com o equipamento Rapha. As feridas estão classificadas em três fases distintas e aspectos de úlcera que variam de uma imagem para a outra. O método automático criado deve ser capaz de distinguir e classificar tais aspectos.`,
    Mamografia: "Mamografia",
    ResMamografia: `Ese projeto aplica métodos e térnicas de aprendizado de
    máquina (machine learning), de forma que seja possível
    reconhecer lesões malignas e benignas em mamografias.`,
    TituloMamografia: "Detecção de lesões em mamografias",
    TxtMamografia: `O objeto deste projeto de pesquisa e desenvolvimento (P&D) é aplicar métodos e técnicas de aprendizado de máquina, de forma que seja possível reconhecer lesões malignas e benignas em mamografias, assim como detectar qual seu tipo. O sistema tem o objetivo de ser feito com algoritmos de Redes Neurais Convolucionais, cuja derivação vem da técnica de Aprendizagem Profunda de Máquina. O intuito é automatizar o reconhecimento de lesões para que ao carregar uma imagem, o sistema identifique sozinho se há ou não alguma lesão, em caso positivo, qual é a sua classificação. A fonte de entrada é um arquivo de uma mamografia digital, contida no computador do médico radiologista que irá realizar o diagnóstico, de forma que esse profissional seja auxiliado em seu trabalho, principalmente em casos que o diagnóstico é mais difícil para o discernimento humano, o sistema apontará áreas nas mamografias em que o radiologista deverá observar com mais cautela, por conta de lesões detectadas.`,
    LesaoPele: "Lesão de pele",
    ResLesaoPele: `Este projeto utilizou de bases de dados abertas e técnicas
    avançadas para treinar uma rede neural que contribui
    no diagnóstico do câncer de pele.`,
    TxtPele: `O câncer de pele assola mais de 14,1 milhões de pacientes e já foi a causa de mais de 8,2 milhões de mortes mundialmente. Ter uma ferramenta capaz de identificar casos precoces ao alcance de pacientes, pode significar mais vidas e menos gastos com tratamento.A incidência de cânceres de pele vem crescendo gradualmente com o passar dos anos. Apenas no biênio de 2018-2019, estima-se que tenham 165 mil novos casos de câncer de pele não melanoma. Onde, os casos detectados e tratados dentro dos primeiros estágios podem ter 97% de chance de sobrevivência, comparado com 15%, caso feito após em estágios avançados. Além disso, o cofre público, que hoje gasta R$ 37 milhões no sistema de saúde, pode se beneficiar de tratamentos mais simples e curtos.`,
    TxtPele2: `Utilizando de bases de dados abertas e técnicas avançadas conseguimos alcançar resultados no estado da arte, acompanhando resultados obtidos em Stanford e Universidade de Seoul.Para o projeto foram utilizadas bases de dados públicas com 12 tipos distintos de lesões de pele, dessas, 4 malignas e 8 benignas. Ao todo foram utilizadas 88 mil imagens para treinar uma rede neural profunda, que ao final obteve em seu pior 89% de verdadeiro positivo sobre falso positivo (quão acurado está o diagnóstico). O que se torna mais significativo quando comparado a acurácia média de 78% de 21 dermatologistas em uma tarefa similar. Isso significa uma nova experiência ao acesso à saúde.`,
    ProjPublicacoes: "Publicações",
    Coordenacao: "Coordenação",
    Gestao: "Gestão",
    PesquisadoresA: "Pesquisadores Associados",
    PesquisadorA: "Pesquisador Associado",
    Pesquisadores: "Pesquisadores",
    Resultados: "Resultados obtidos",
    Alei: "Projeto ALEI",
    AleiTitulo: "Análise Legal Inteligente (ALEI)",
    ResAlei:
      "O projeto Análise Legal Inteligente (ALEI) atenta-se à digitalização dos processos judiciais do TRF1 e tem como objetivo automatizar fases processuais de segunda instância do TRF1 de forma computacional.",
    AleiTxt1:
      "Tratado por ALEI (Análise Legal Inteligente), o projeto volta-se especificamente para as necessidades dos gabinetes de 2o Grau.",
    AleiTxt2:
      "Dando sequência à atual fase de digitalização dos processos judiciais do TRF1, o ALEI, projeto de pesquisa e desenvolvimento (P&D) a ser desenvolvido entre o TRF1 e GPAM deverá utilizar a base de textos jurídicos digitalizados como insumo para o desenvolvimento de tecnologias calcadas em IA para automatizar fases processuais de segunda instância do TRF1 de forma computacional. Para tanto, propõe-se a sistematização do banco de processos judiciais do TRF1 par criar um “corpus” que servirá como objeto de análises de um sistema baseado em algoritmos de Aprendizado de Máquina (AM), incluindo possíveis aplicações de Redes Neurais Profundas (DNN, do inglês Deep Neural Network). ",
    AleiTxt3:
      "Este projeto possui um objeto de pesquisa de muita relevância no cenário nacional e internacional, tanto do ponto de vista da técnica como de sua aplicação. Da técnica, visa ao desenvolvimento de tecnologia inovadora em termos de métodos de reconhecimento de padrões em textos oriundos da área de Inteligência Artificial, especificamente AM e PNL. Do ponto de vista da aplicação, o uso de métodos de AM/PNL em contextos jurídicos também configura tecnologia ainda pouco pesquisada e explorada no cenário internacional. Como o Brasil possui o cenário de centena de milhões de processos, incluindo altas taxas de morosidade/congestionamento, o campo para aplicação de AM/PNL é muito amplo.",
    AleiTxt:
      "Tratado por ALEI (Análise Legal Inteligente), o projeto volta-se especificamente para as necessidades dos gabinetes de 2o Grau. Dando sequência à atual fase de digitalização dos processos judiciais do TRF1, o ALEI, projeto de pesquisa e desenvolvimento (P&D) a ser desenvolvido entre o TRF1 e GPAM deverá utilizar a base de textos jurídicos digitalizados como insumo para o desenvolvimento de tecnologias calcadas em IA para automatizar fases processuais de segunda instância do TRF1 de forma computacional. Para tanto, propõe-se a sistematização do banco de processos judiciais do TRF1 par criar um “corpus” que servirá como objeto de análises de um sistema baseado em algoritmos de Aprendizado de Máquina (AM), incluindo possíveis aplicações de Redes Neurais Profundas (DNN, do inglês Deep Neural Network). Este projeto possui um objeto de pesquisa de muita relevância no cenário nacional e internacional, tanto do ponto de vista da técnica como de sua aplicação. Da técnica, visa ao desenvolvimento de tecnologia inovadora em termos de métodos de reconhecimento de padrões em textos oriundos da área de Inteligência Artificial, especificamente AM e PNL. Do ponto de vista da aplicação, o uso de métodos de AM/PNL em contextos jurídicos também configura tecnologia ainda pouco pesquisada e explorada no cenário internacional. Como o Brasil possui o cenário de centena de milhões de processos, incluindo altas taxas de morosidade/congestionamento, o campo para aplicação de AM/PNL é muito amplo.",
    EmAndamento: "Em andamento",
    Just40: "Justiça 4.0",
    Just40Title:
      "JUSTIÇA 4.0 BRA/20/015 - INOVAÇÃO E EFETIVIDADE NA REALIZAÇÃO DA JUSTIÇA PARA TODOS",
    Just40Descricao:
      "O projeto visa o desenvolvimento de uma solução de Inteligência Artificial para realizar a identificação de similaridade entre demandas e precedentes qualificados. A solução a ser desenvolvida terá como objetivo disponibilizar, de forma ordenada, os precedentes qualificados proferidos pelos tribunais superiores, tornando para o usuário, tanto quanto possível, mais fácil o resgate dos precedentes qualificados de interesse para solução do caso específico em análise. Desta forma, a proposta do trabalho a ser desenvolvida no âmbito desta parceria é desenvolver uma solução de Inteligência Artificial que realizará a busca e análise dos precedentes qualificados, com foco nos tribunais superiores (STJ e STF), de forma que o algoritmo resultante seja capaz de identificar precedentes qualificados adequados para o apoio a decisão de modo a possibilitar o agrupamento por similaridade",
    Just40Andamento:
      "Situação: Em andamento; Natureza: Pesquisa. Ano início: 2021 - Ano Término: -",
    Sabia: "Sabiá",
    SabiaDescricao:
      "O projeto tem como escopo pesquisar e desenvolver soluções para complementar o sistema Bem-Te-Vi no que se refere às funcionalidades de Agrupamento de Processos e Levantamento de Jurisprudência. Visando evidenciar o desempenho real destas soluções, é objetivo deste trabalho desenvolver um módulo de Registro de Feedbacks dos usuários. Por fim, este projeto também visa pesquisar soluções de Long Life Machine Learning (LLML) com o objetivo de desenvolver uma solução que traga um ciclo de vida longo aos modelos de IA implantados. Em síntese, este projeto visa o desenvolvimento de 2 (duas) soluções de IA, um sistema de registro de desempenho destas IAs e uma solução de LLML",
    SabiaAndamento:
      "Situação: Em andamento; Natureza: Pesquisa. Ano início: 2021 - Atual",
    ProjetosTxtEmDesenvolvimento:
      "Projetos em desenvolvimento pela equipe de pesquisadores e professores do AI.lab.",
    Alei1a: "ALEI 1ª Instância",
    Alei1aTitle:
      "“PLATAFORMA DE INTELIGÊNCIA ARTIFICIAL PARA O JUDICIÁRIO: APLICAÇÃO NA JUSTIÇA FEDERAL” - ALEI 1ª Instância:",
    Alei1aDescricao:
      "O projeto visa estruturar o Núcleo de Inteligência Artificial aplicada à Justiça para integrar a cadeia de inovação de produtos e serviços em Inteligência artificial aplicada à Justiça, com vistas a construir alternativas de melhoria no Judiciário, bem como potencializar o aquecimento do ecossistema de inovação tecnológica no Distrito Federal, atuando com base na pesquisa, capacitação e empreendedorismo.",
    Alei1aAndamento:
      "Situação: Em andamento; Natureza: Pesquisa. Ano início: 2021 - Atual",
    Osiris: "Osíris",
    OsirisTitle: "“Inteligência Artificial em Execução Fiscal” - Osíris",
    OsirisDescricao:
      "O projeto tem como objetivo pesquisar e desenvolver soluções que auxiliem a PGDF no encaminhamento das demandas associadas aos processos de execução fiscal, além de capacitar seus servidores para que possam manter e evoluir os resultados. Os desafios a seguir enumeram questões mais concretas que o projeto deverá contemplar:",
    OsirisLista:
      "Leitura de peças jurídicas para a identificação de fases processuais, comparação de dados para a tomada de decisão e automatização de atividades repetitivas; Automatização de geração de petições jurídicas nas execuções fiscais nos sistemas de automação da Procuradoria-Geral do Distrito Federal, por meio da Procuradoria-Geral Adjunta da Fazenda Distrital (“PGFAZ”), através de API’s (Application Programming Interface); Integração das aplicações tecnológicas nos sistemas automatizados do Tribunal de Justiça do Distrito Federal e Territórios (“TJDFT”), por meio da Vara de Execuções Fiscais do Distrito Federal (“VEF/DF”), para sugestão de pronunciamentos judiciais sequenciais as manifestações repetitivas da PGFAZ.",
    OsirisAndamento:
      "Situação: Em andamento; Natureza: Pesquisa. Ano início: 2021 - Atual",
  },
};

export default Pt;
