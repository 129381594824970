import React from "react";

import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Notices2022 } from "./components/notices2022";
import { Notices2023 } from "./components/notices2023";
import { Notices2024 } from "./components/notices2024";

const Notices = () => {
  const { t } = useTranslation();
  const [year, setYear] = React.useState("");

  return (
    <div style={divStyle}>
      <h1 style={headerStyle}>Editais</h1>
      {year === "" && (
        <>
          <Card style={cardStyle}>
            <h2 style={textStyle}>Editais 2024</h2>
            <Button variant="outline-dark" onClick={() => setYear("2024")}>
              {t("Visualizar")}
            </Button>
          </Card>

          <Card style={cardStyle}>
            <h2 style={textStyle}>Editais 2023</h2>
            <Button variant="outline-dark" onClick={() => setYear("2023")}>
              {t("Visualizar")}
            </Button>
          </Card>

          <Card style={cardStyle}>
            <h2 style={textStyle}>Editais 2022</h2>
            <Button variant="outline-dark" onClick={() => setYear("2022")}>
              {t("Visualizar")}
            </Button>
          </Card>
      </>)}

      {year === "2024" && <Notices2024 setYear={setYear} />}
      
      {year === "2023" && <Notices2023 setYear={setYear} />}

      {year === "2022" && <Notices2022 setYear={setYear} />}
    </div>
  );
};

const textStyle = {
  textAlign: "left",
  margin: "20px",
  fontSize: "18px",
};

const divStyle = {
  width: "90%",
  margin: "40px auto",
};

const headerStyle = {
  fontSize: "40px",
  textAlign: "left",
};

const cardStyle = {
  marginTop: "20px",
};

export default Notices;
