import Ailab1 from "../components/assets/ailab1.webp";
import Ailab2 from "../components/assets/ailab2.webp";
import Ailab3 from "../components/assets/ailab3.webp";
import Ailab4 from "../components/assets/ailab4.webp";
import Ailab5 from "../components/assets/ailab5.webp";
import Ailab6 from "../components/assets/ailab6.webp";

export const ailab = [
  {
    image: Ailab1,
  },
  {
    image: Ailab2,
  },
  {
    image: Ailab3,
  },
  {
    image: Ailab4,
  },
  {
    image: Ailab5,
  },
  {
    image: Ailab6,
  },
];
